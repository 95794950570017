import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { chatService } from "../services/chat.service";
import PopupComponent from "../modules/ImageModal";
import { format } from "date-fns";
import { accountService } from "../services";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { uploadFile } from "../utils/SupportChat";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

import EmojiPicker from "emoji-picker-react";
const videoImg = require("../assets/video.png");

interface ChatMain {
  setShowChat: (showChat: boolean) => void;
  selectedUserId: string;
  loggedUser: any;
}
export default function ChatMain({ selectedUserId, loggedUser }: ChatMain) {
  const [conversationData, setConversationData] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [videoAttachment, setVideoAttachment] = useState<boolean>();
  const [imageModal, setImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const textFieldRef = useRef<HTMLDivElement>(null); // Use this to refer to the TextField

  const [sasToken, setSasToken] = useState("");
  const [storageAccount, setStorageAccount] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [conversationData]);
  function formatMessageDate(date: any) {
    console.log(date);

    if (!date) return "";

    let user = accountService.userValue;

    let utcDate = new Date(date);

    if (user.metadata != null && user.metadata.timezoneOffset != null) {
      utcDate = new Date(
        utcDate.getTime() +
          new Date().getTimezoneOffset() * 60 * 1000 +
          Number(user.metadata.timezoneOffset) * 60 * 1000
      );
    }

    return format(utcDate, "MMMM dd, yyyy hh:mm a");
  }
  function extractUserNameFromEmail(email: string) {
    // Split the email string at the '@' character
    const emailParts = email.split("@");

    // Check if the split resulted in an array with at least 2 elements
    if (emailParts.length >= 2) {
      // Return the part before the '@' character
      return emailParts[0];
    }

    // If the email format is incorrect, return an empty string or handle it appropriately
    return "";
  }

  const handleSend = async () => {
    try {
      const user = await accountService.userValue;

      setButtonLoading(true);
      try {
        if (attachments.length > 0) {
          /// Storing The File in azure storage and getting the link---------/
          let urlArray = [];
          for (let i = 0; i < attachments.length; i++) {
            const response = await uploadFile(
              attachments[i].file,
              selectedUserId,
              user.role
            );
            urlArray.push(response);
          }
          setAttachments([]);
          /// storing the message on the backend------------------/
          let conversation = [
            {
              role: "support",
              content: newMessage,
              attachments: urlArray,
              supportId: user.id,
            },
          ];

          const chatResponse = await chatService.supportChatPost(
            selectedUserId,
            conversation,
            user.id
          );
          // let userNameFromEmail = extractUserNameFromEmail(user.email);
          let userName = user.name;
          let updatedConversation = [
            {
              role: "support",
              content: newMessage,
              attachments: urlArray,
              supportId: { id: user.id, name: userName },
              date: Date.now(),
            },
          ];
          if (chatResponse) {
            setNewMessage("");
            setConversationData([...conversationData, ...updatedConversation]);
          } else {
            alert("unable to proceed the request");
          }
        } else {
          let conversation = [
            {
              role: "support",
              content: newMessage,
              supportId: user.id,
            },
          ];

          const chatResponse = await chatService.supportChatPost(
            selectedUserId,
            conversation,
            user.id
          );
          // let userNameFromEmail = extractUserNameFromEmail(user.email);
          let userName = user.name;
          let updatedConversation = [
            {
              role: "support",
              content: newMessage,
              supportId: { id: user.id, name: userName },
              date: Date.now(),
            },
          ];
          if (chatResponse) {
            setNewMessage("");
            setConversationData([...conversationData, ...updatedConversation]);
          } else {
            alert("unable to proceed the request");
          }
        }
      } catch (error) {
        alert("Unable to procceed right now");
        setNewMessage("");
      }
    } catch (error: any) {
      alert(`Unable to procceed right now ${error.message}`);
      setNewMessage("");
    }

    setButtonLoading(false);
  };
  //// Function For Fetching The Message Data------------------/
  async function fetchMessageData() {
    /// api call code for getting the message----/
    setLoading(true);
    try {
      const user = await accountService.userValue;

      setSasToken(user.sasToken);
      setStorageAccount(user.storageAccount);
      const chatResponse = await chatService.supportChatGet(selectedUserId);
      console.log("this is chat");

      console.log(chatResponse);

      if (chatResponse) {
        setConversationData(chatResponse.conversation);
        setLoading(false);
      } else {
        setLoading(false);
        alert("Unable to load the chat");
      }
    } catch (error) {
      setLoading(false);
      alert("Unable to load the chat");
    }
  }

  //// Function For handeling the attachments -----------------------------/
  const handleAttachment = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files) {
      const newAttachments = Array.from(files).map((file) => ({
        file: file,
        url: URL.createObjectURL(file),
      }));

      setAttachments((currentAttachments) => {
        // Combine old and new attachments
        const combinedAttachments = [...currentAttachments, ...newAttachments];

        // Slice the array to only keep the first 4 attachments
        return combinedAttachments.slice(0, 4);
      });

      if (files.length + attachments.length > 4) {
        alert(
          "You can only select up to 4 files. Only the first 4 files have been added."
        );
      }
    } else {
      alert("Unable to select the image. Try again.");
    }
  };

  /// Function for  and opening the images ------------------------------/

  function openAttachment(attachment: any, isVideo: any) {
    if (isVideo) {
      setImageUrl(attachment);
      setImageModal(true);
      setVideoAttachment(true);
    } else {
      /// image has been clicked------------/
      setImageUrl(attachment);
      setImageModal(true);
      setVideoAttachment(false);
    }
  }

  function chunkArray(array: any, size: any) {
    return array.reduce((acc: any, val: any, i: any) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);
      return acc;
    }, []);
  }

  // Function to check if the URL is a video
  const isVideoUrl = (url: any) => {
    const mp4Index = url.indexOf(".mp4");
    const queryIndex = url.indexOf("?");
    return mp4Index > -1 && (queryIndex === -1 || mp4Index < queryIndex);
  };

  function resetStates() {
    setConversationData([]);
    setNewMessage("");
    setVideoAttachment(undefined);
    setImageModal(false);
    setImageUrl(undefined);
  }

  useEffect(() => {
    resetStates();
    fetchMessageData();
  }, [selectedUserId]);

  const handleRemoveAttachment = (indexToRemove: any) => {
    setAttachments((currentAttachments) =>
      currentAttachments.filter((_, index) => index !== indexToRemove)
    );
  };
  const onEmojiClick = (emojiData: any, event: any) => {
    // Debugging: Log the emoji data
    if (emojiData && emojiData.emoji) {
      setNewMessage((prevInput) => prevInput + emojiData.emoji);
    } else {
      console.error("No emoji found in the selected emoji data");
    }
    setShowEmojiPicker(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid form submission

      const textarea = textFieldRef.current?.querySelector("textarea");
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = newMessage;
        const before = text.substring(0, start); // Text before the cursor
        const after = text.substring(end); // Text after the cursor

        // Update the state with the new string containing the inserted newline
        setNewMessage(`${before}\n${after}`);

        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = start + 1;

          // Determine the need to scroll based on cursor position within the viewport
          const lineHeight = parseInt(
            getComputedStyle(textarea).lineHeight,
            10
          );
          const linesVisible = Math.floor(textarea.clientHeight / lineHeight);
          const cursorLine = (before.match(/\n/g) || []).length + 1; // Get line number of cursor
          const totalLines = (text.match(/\n/g) || []).length + 1; // Total lines in text

          if (cursorLine > totalLines - linesVisible) {
            textarea.scrollTop = textarea.scrollHeight;
          }
        }, 0);
      }
    } else if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid inserting a new line
    }
  };
  useEffect(() => {
    console.log("useffect call");
    console.log(sasToken);
    console.log(storageAccount);
  }, [sasToken, storageAccount]);

  return (
    <Container style={{ height: "100vh", marginTop: 20 }}>
      {loggedUser === selectedUserId ? (
        <Container>
          <Typography style={{ textAlign: "center", marginTop: 40 }}>
            You can't Start a conversation with yourself
          </Typography>
        </Container>
      ) : (
        <>
          {" "}
          <AppBar position="static">
            <Toolbar>
              <Box sx={{ height: "100%" }}>
                {loading ? (
                  <Container>
                    <CircularProgress />
                  </Container>
                ) : (
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                    }}
                  >
                    <img
                      src={`https://${storageAccount}.blob.core.windows.net/user-data/${selectedUserId}/profile-pic?${sasToken}`}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Box>
              <Box flexGrow={1} />
              <IconButton edge="end" color="inherit"></IconButton>

              <IconButton edge="start" color="inherit">
                <ChatIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Paper
            style={{
              height: "60%",
              overflow: "auto",
              marginBottom: "16px",
            }}
            ref={chatContainerRef}
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {loading ? (
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Container>
              ) : (
                <>
                  {" "}
                  {conversationData && conversationData.length === 0 ? (
                    <Container
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>No conversation yet</Typography>
                      <Typography style={{ marginTop: 4 }}>
                        Start your conversation...
                      </Typography>
                    </Container>
                  ) : (
                    <>
                      {conversationData &&
                        conversationData.length !== 0 &&
                        conversationData.map((item, index) => (
                          <Box
                            key={index}
                            style={{
                              alignSelf:
                                item?.role === "support"
                                  ? "flex-end"
                                  : "flex-start",
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 12,
                              maxWidth: "80%",
                              position: "relative", // Add relative positioning here
                              marginRight: 6,
                            }}
                          >
                            <Container
                              style={{
                                backgroundColor:
                                  item?.role === "support"
                                    ? "rgba(195, 209, 237, 0.40)"
                                    : "rgba(195, 237, 205, 0.40)",
                                padding: 10,
                                marginLeft: item?.role !== "support" ? 3 : 0,
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                borderBottomLeftRadius:
                                  item?.role === "support" ? 20 : 0,
                                borderBottomRightRadius:
                                  item?.role === "support" ? 0 : 20,
                                width: "auto",
                                maxWidth: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "'Poppins', sans-serif",
                                    whiteSpace: "pre-wrap", // Ensures that whitespace and newlines are preserved
                                  }}
                                >
                                  {item?.content}
                                </Typography>
                                {/* Display support agent's name if available */}
                                {item.role === "support" && item.supportId && (
                                  <Typography
                                    style={{
                                      fontFamily: "'Poppins', sans-serif",
                                      textAlign: "end",
                                      fontSize: 8,
                                    }}
                                  >
                                    {item.supportId.name
                                      ? item.supportId.name
                                      : item.supportId.email
                                      ? extractUserNameFromEmail(
                                          item.supportId.email
                                        )
                                      : ""}
                                  </Typography>
                                )}
                                {item.date ? (
                                  <Typography
                                    style={{
                                      fontFamily: "'Poppins', sans-serif",
                                      textAlign: "end",
                                      fontSize: 8,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: "'Poppins', sans-serif",
                                        textAlign: "end",
                                        fontSize: 8, // Adjust for date display
                                        color: "#888", // Light grey color for the date
                                      }}
                                    >
                                      {formatMessageDate(item.date)}
                                    </Typography>
                                  </Typography>
                                ) : null}
                                {item.attachments &&
                                  item.attachments.length > 0 &&
                                  chunkArray(item.attachments, 3).map(
                                    (row: any, rowIndex: any) => (
                                      <div
                                        key={rowIndex}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {row.map(
                                          (
                                            attachment: any,
                                            attachmentIndex: any
                                          ) => {
                                            const isVideo =
                                              isVideoUrl(attachment);

                                            return (
                                              <div
                                                key={`${rowIndex}-${attachmentIndex}`}
                                                style={{
                                                  position: "relative",
                                                  maxWidth: "100%",
                                                }}
                                                onClick={() =>
                                                  openAttachment(
                                                    attachment,
                                                    isVideo
                                                  )
                                                }
                                              >
                                                {isVideo ? (
                                                  <img
                                                    src={videoImg}
                                                    alt={`attachment-${attachmentIndex}`}
                                                    style={{
                                                      width: "80%",
                                                      height: "50px",
                                                      display: "block",
                                                      margin: "auto",
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    src={attachment}
                                                    alt={`attachment-${attachmentIndex}`}
                                                    style={{
                                                      width: "100px", // fixed width
                                                      height: "80px", // fixed height
                                                      objectFit: "cover", // resize the image to cover the container while preserving aspect ratio
                                                      display: "block",
                                                      margin: "auto",
                                                      paddingRight: 2,
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )
                                  )}
                              </Box>
                            </Container>
                            {item.role === "support" && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: -8, // Adjust as needed to position the image below the container
                                  right: -4,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: 18,
                                    height: 19,
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                  }}
                                >
                                  <img
                                    src={`https://${storageAccount}.blob.core.windows.net/user-data/${item.supportId}/profile-pic?${sasToken}`}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              </Box>
                            )}
                          </Box>
                        ))}
                    </>
                  )}
                </>
              )}
            </Box>
          </Paper>
          <Paper style={{ height: "auto" }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* Container for attachment previews */}
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  marginBottom: 0,
                  whiteSpace: "nowrap",
                }}
              >
                {attachments.map((attachment, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={attachment.url}
                      alt={`attachment-${index}`}
                      style={{
                        width: 50,
                        height: 50,
                        objectFit: "cover",
                        marginBottom: 5,
                      }}
                    />
                    <button onClick={() => handleRemoveAttachment(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              {/* Input field and buttons */}
              <Box p={2} display="flex" alignItems="center" width="100%">
                <input
                  type="file"
                  multiple
                  accept="image/*,video/*"
                  style={{ display: "none" }}
                  id="file-input"
                  onChange={handleAttachment}
                />
                <label htmlFor="file-input">
                  <AttachmentIcon style={{ fontSize: 30, marginRight: 5 }} />
                </label>
                <EmojiEmotionsIcon
                  onClick={() => setShowEmojiPicker((val) => !val)}
                  style={{ fontSize: 25, marginRight: 5, cursor: "pointer" }}
                />
                {showEmojiPicker && (
                  <div
                    style={{ position: "absolute", bottom: "70px", zIndex: 50 }}
                  >
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                  </div>
                )}
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={3}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  ref={textFieldRef} // Attach the ref here
                />

                {buttonLoading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 3,
                    }}
                  >
                    <CircularProgress size={25} />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleSend}
                    style={{ marginLeft: "16px" }}
                  >
                    Send
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </>
      )}
      <PopupComponent
        isPopupOpen={imageModal}
        imageUrl={imageUrl}
        setIsPopupOpen={setImageModal}
        isVideo={videoAttachment}
      />
    </Container>
  );
}
