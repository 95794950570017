/** @format */

import React, { act, useEffect, useRef, useState } from "react";
import { Grid, Snackbar } from "@mui/material";

import TopAppBar from "../modules/TopAppBar";

import { accountService } from "../services";
import { additionalService } from "../services/additional.service";
import "../css/client.css";

import FormNotification from "../modules/FormNotification";
import PasswordModal from "../modules/PasswordModal";
import NotificationSideBar from "../modules/NotificationSideBar";
import { useLocation } from "react-router-dom";
import { SelectedUserInterface } from "../interface/User";

import {
  FilterInterface,
  FiltersInterface,
} from "../interface/FilterInterface";
import { notificationUtils } from "../utils/Notification";

/// Main Funciton of the componenet----------------------------------------------------/
const Notification = () => {
  const location = useLocation();
  const [chunkSize, setChunkSize] = useState(50);
  const [avoidDuplicateTitle, setAvoidDuplicateTitle] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [userArray, setUserArray] = useState<any[]>();
  const [passwordModalOpen, setpasswordModalOpen] = useState(false);
  const [allUserForNotification, setAllUserForNotification] = useState<any[]>();
  const [msg, setMsg] = useState("");
  const { users, totalCount } = location.state || {};

  const [userLoading, setUserLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [fixedUsers, setFixedUsers] = useState<SelectedUserInterface[]>([]); // Initialize with an empty array
  const [totalUsers, settotalUsers] = useState<SelectedUserInterface[]>([]);
  const [totalCheckItemNumber, setTotalCheckItemNumber] = useState(0);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    type: "",
  });

  const [firstTimeLoad, setFirstTimeLoad] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // new state to track loading more users
  const listInnerRef = useRef<HTMLUListElement>(null);
  const [numberofCalls, setNumberOfCalls] = useState(2);
  const [selectAll, setSelectAll] = useState(false);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [fixedUserNumber, setFixedUserNumber] = useState(0);
  function getObjectSize(object: any) {
    const jsonString = JSON.stringify(object);
    const bytes = new TextEncoder().encode(jsonString).length;
    return bytes;
  }
  //// Function For handeling the api call--------------------------/
  async function handelApiCall(
    title: string,
    body: string,
    type: string,

    password?: any
  ) {
    setLoading(true);

    //// Function for checking if title duplication is there or not
    /// if it is there then it will remove the user who has true value in notification modal
    console.log("this is selecteduserids for notification");

    console.log(selectedUserIds);

    const finalUsersToSendNotification =
      await notificationUtils.finalUserToSendNotification(
        selectedUserIds,
        avoidDuplicateTitle,
        title
      );

    console.log(`this is final user to send notification`);
    console.log(finalUsersToSendNotification);

    if (finalUsersToSendNotification.success) {
      try {
        /// Authenticating The User-----------------------------------------------------/
        if (selectedUserIds.length == 1) {
          console.log("went for the single user");

          // user is making the call for single user
          /// getting the queuestring---------------------------------------------------/
          const queueResponse = await accountService.getQueueString();
          const { message, success, data } = queueResponse;
          console.log(`this is queue response`);
          console.log(queueResponse);

          if (success) {
            let messageObject = {
              userArray: finalUsersToSendNotification.users,
              title: title,
              body: body,
              type: type,
            };

            notificationUtils.sendMessageToQueue(
              data.queueName,
              JSON.stringify(messageObject),
              data.queueString
            );

            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Notification has been sent");
          } else {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Error happened in fetching queue result");
          }
        } else {
          console.log("else for the password is matching");

          /// password will be there so user will be authenticated---------------------/
          const userDetails = await accountService.userValue;
          const userAuthResponse = await accountService.signin(
            userDetails.email,
            password
          );

          if (userAuthResponse) {
            // user is making the call for single user
            /// getting the queuestring---------------------------------------------------/
            const queueResponse = await accountService.getQueueString();
            const { message, success, data } = queueResponse;

            if (success) {
              const userChunks = await notificationUtils.chunkArray(
                finalUsersToSendNotification.users,
                chunkSize
              );

              userChunks.forEach((userChunk, index) => {
                let messageObject = {
                  userArray: userChunk,
                  title: title,
                  body: body,
                  type: type,
                };

                notificationUtils.sendMessageToQueue(
                  data.queueName,
                  JSON.stringify(messageObject),
                  data.queueString
                );
              });

              setLoading(false);
              setSnackbarOpen(true);
              setSnackbarMessage("Notification has been sent");
            } else {
              setLoading(false);
              setSnackbarOpen(true);
              setSnackbarMessage("Error happened in fetching queue result");
            }
          } else {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("You are not authenticated");
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);

        setSnackbarOpen(true);
        setSnackbarMessage("some error happend");
      }
    } else {
      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMessage(finalUsersToSendNotification.message);
    }

    // } else {
    //   setLoading(false);
    //   setSnackbarOpen(true);
    //   setSnackbarMessage(message);
    // }
  }

  /// Funciton For sending the Notification--------------/
  async function sendUserNotification(
    title: string,
    body: string,
    type: string
  ) {
    if (selectedUserIds.length === 0) {
      /// no user has selected so will show alert---------------------/
      alert("Kindly select any user first");
    } else if (selectedUserIds.length === 1) {
      ///admin has selected one user only so won't show the modal-----/

      try {
        //// Getting The main User----------------------------------/

        handelApiCall(title, body, type, null);
      } catch (error) {
        alert("Unable to send the notificaiton");
        setSelectedUserIds([]);
      }
    } else if (selectedUserIds.length > 1) {
      /// admin/trainer has selected more then one user so will show the modal---------/
      setFormData({ title: title, body: body, type: type });
      setpasswordModalOpen(true);
    }
  }

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const fetchUsers = async () => {
    console.log("called fetchuser");

    setUserLoading(true);
    try {
      const user = accountService.userValue;
      let numberofCalls = 1;
      const params = {
        numberofCalls: numberofCalls,
        userGroupNumber: 20,
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        activity: {
          date: null,
          activityType: "",
        },
        dateRange: {
          startDate: null,
          endDate: null,
        },
      };

      const res = await accountService.getAllUsers(params);
      let { users, userCount } = res;
      const sortedUsers = users.sort((a: any, b: any) => {
        if (a.messageSeen === b.messageSeen) {
          return 0;
        }
        return a.messageSeen ? -1 : 1;
      });
      setFirstTimeLoad(true);

      // Set the sorted users
      settotalUsers(sortedUsers);
      setFixedUsers(sortedUsers);
      setUserLoading(false);
      setFixedUserNumber(userCount);
      setTotalNumberOfUsers(userCount);
      const loggedUserr = await accountService.userValue;

      /// Service function call for getting the country names-/
      const countryApiResponse = await accountService.getCountries(
        loggedUserr.id
      );
      console.log("this is country api response");

      console.log(countryApiResponse);

      if (countryApiResponse.success === true) {
        setCountries([...countryApiResponse.countryArray]);
      } else {
        setCountries([]);
      }
    } catch (error) {
      setUserLoading(false);
    }
  };
  /////////////// fETCHING THE User if user details is not there----/
  useEffect(() => {
    if (users !== undefined && users.length > 0) {
      settotalUsers(users);
      setFixedUsers(users);
      setFixedUserNumber(totalCount);
      setTotalNumberOfUsers(totalCount);
    } else {
      fetchUsers();
    }
  }, []);

  const fetchMoreUsers = async (
    numberofCalls: any,
    filters: FilterInterface,
    hasMoreUsers: boolean
    // Add a new parameter for
  ) => {
    console.log("fetchmore user is called");

    // Only fetch more if we previously determined there are more users to fetch
    if (!hasMoreUsers) {
      return;
    }
    setIsLoadingMore(true);

    // Implement logic to fetch more users based on the next request number
    // This is similar to your existing fetchUsers but increments reqestCallForUsers and fetches additional users
    try {
      const user = accountService.userValue;

      const params = {
        numberofCalls: numberofCalls,
        userGroupNumber: 20,
        deviceType: filters.deviceType, // Add device type if active
        onboarding: filters.onboardingFilter, // Add onboarding status if active
        subscription: filters.subscriptionFilter, // Add subscription status if active
        trainerCode: user.role === "admin" ? undefined : user.uniqueTrainerCode, // Add trainer code if user is not admin
        countries: filters.checkBoxCountries, // Include selected countries in the request
        notification: filters.notificationFilter,
        maxVersion: filters.maxVersion,
        minVersion: filters.minVersion,
        genderType: filters.genderType,
        goalType: filters.goalType,
        activeType: filters.activeType,
        activity: filters.activity,
        dateRange: filters.dateRange,
        contactNumber: filters.contactNumberFilter,
        notes: filters.notesFilter,
        uncheckedCountries: filters.uncheckCountries,
        messageSeen: filters.messageSeenFilter,
        countryCode: filters.countryCode,
        csvFile: filters.csvFileFilter,
        distinctIds: filters.distinctIds,
        openSupportMessage: filters.openSupportMessage,
        minimumAge: filters.minimumAge,
        maximumAge: filters.maximumAge,
      };

      const additionalUsers = await accountService.getAllUsers(params);

      let { users, userCount } = additionalUsers;
      if (users.length > 0) {
        // setFixedUsers((prevUsers: any) => [...prevUsers, ...users]);
        settotalUsers((prevUsers: any) => [...prevUsers, ...users]);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
        setTotalNumberOfUsers(userCount);
        // Update selectedUserIds state with new user IDs if selectAll is true
        if (selectAll) {
          const newSelectedUsers = users.map((user: any) => ({
            userId: user._id,
            fcmToken: user.fcmToken,
          }));
          setSelectedUserIds((prevSelectedIds) => [
            ...prevSelectedIds,
            ...newSelectedUsers,
          ]);
        }
      } else {
        setHasMoreUsers(false);
        setNumberOfCalls((prevNumber) => prevNumber + 1);
      }
    } catch (error) {
      console.error("Failed to load more users:", error);
    }
    setIsLoadingMore(false);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <TopAppBar />
      </Grid>
      <Grid item container>
        <Grid item sx={{ p: 1 }} xs={12} md={3}>
          <NotificationSideBar
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            settotalUsers={settotalUsers}
            totalUsers={totalUsers}
            fixedUsers={fixedUsers}
            userLoading={userLoading}
            totalNumberOfUsers={totalNumberOfUsers}
            handleScroll={fetchMoreUsers}
            listInnerRef={listInnerRef}
            isLoadingMore={isLoadingMore}
            setUserLoading={setUserLoading}
            fetchUsers={fetchUsers}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            numberofCalls={numberofCalls}
            setNumberOfCalls={setNumberOfCalls}
            setTotalNumberOfUsers={setTotalNumberOfUsers}
            totalCheckItemNumber={totalCheckItemNumber}
            setTotalCheckItemNumber={setTotalCheckItemNumber}
            selectType="id"
            userArray={userArray}
            setUserArray={setUserArray}
            fixedUserNumber={fixedUserNumber}
            setFixedUserNumber={fixedUserNumber}
            hasMoreUsers={hasMoreUsers}
            setHasMoreUsers={setHasMoreUsers}
            firstTimeLoad={firstTimeLoad}
            setFirstTimeLoad={setFirstTimeLoad}
            countries={countries}
          />
        </Grid>
        <Grid item xs={12} md={9} sx={{ p: 1 }}>
          <FormNotification
            sendUserNotification={sendUserNotification}
            loading={loading}
            chunkSize={chunkSize}
            setChunkSize={setChunkSize}
            avoidDuplicateCall={avoidDuplicateTitle}
            setAvoidDuplicateCall={setAvoidDuplicateTitle}
          />
        </Grid>
      </Grid>

      <PasswordModal
        setpasswordModalOpen={setpasswordModalOpen}
        passwordModalOpen={passwordModalOpen}
        formData={formData}
        selectedUserIds={selectedUserIds}
        handelApiCall={handelApiCall}
        loading={loading}
        msg={msg}
        setMsg={setMsg}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
    </Grid>
  );
};

export default Notification;
